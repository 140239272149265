import { default as _dep_workspacesKfLlx8E3zTMeta } from "/workspace/pages/_dep_workspaces.vue?macro=true";
import { default as createKyc5q5j6kBMeta } from "/workspace/pages/admin/blog/create.vue?macro=true";
import { default as indexuLsMercjz6Meta } from "/workspace/pages/admin/blog/index.vue?macro=true";
import { default as index8x3i9klHioMeta } from "/workspace/pages/admin/index.vue?macro=true";
import { default as indexgWMKjCA6tUMeta } from "/workspace/pages/admin/user/index.vue?macro=true";
import { default as assistantNpE3wpoAOiMeta } from "/workspace/pages/assistant.vue?macro=true";
import { default as indexIU5K3dgkCOMeta } from "/workspace/pages/blog/[post]/index.vue?macro=true";
import { default as indexRgrKfU8NaHMeta } from "/workspace/pages/blog/index.vue?macro=true";
import { default as canvaszfRK4TnYgaMeta } from "/workspace/pages/canvas.vue?macro=true";
import { default as changelogInHvtgjw9VMeta } from "/workspace/pages/changelog.vue?macro=true";
import { default as _91_46_46_46slug_932Tk4dBzvWOMeta } from "/workspace/pages/community/[...slug].vue?macro=true";
import { default as privacylfhMLOhpAWMeta } from "/workspace/pages/docs/privacy.vue?macro=true";
import { default as tosKaBtqC7TH5Meta } from "/workspace/pages/docs/tos.vue?macro=true";
import { default as _91pageId_936WmMFF7uH6Meta } from "/workspace/pages/duplicate/[pageId].vue?macro=true";
import { default as exploreoxnV20dS4hMeta } from "/workspace/pages/explore.vue?macro=true";
import { default as faqwxhi5zHHNsMeta } from "/workspace/pages/faq.vue?macro=true";
import { default as aiaH63aphHpSMeta } from "/workspace/pages/features/ai.vue?macro=true";
import { default as flashcardsEaFURn8qYlMeta } from "/workspace/pages/features/flashcards.vue?macro=true";
import { default as image_to_pdfSrpwo9L3lOMeta } from "/workspace/pages/features/image_to_pdf.vue?macro=true";
import { default as index11ooPhzO1bMeta } from "/workspace/pages/features/index.vue?macro=true";
import { default as notessOXHEHnmVAMeta } from "/workspace/pages/features/notes.vue?macro=true";
import { default as forgot_password1lkVCZfq00Meta } from "/workspace/pages/forgot_password.vue?macro=true";
import { default as adding_45flashcard_45images9qtfBA8au5Meta } from "/workspace/pages/help/adding-flashcard-images.vue?macro=true";
import { default as ai_45text_45completion6BtjXubXDnMeta } from "/workspace/pages/help/ai-text-completion.vue?macro=true";
import { default as ai_45text_45editBkiIpGVGS9Meta } from "/workspace/pages/help/ai-text-edit.vue?macro=true";
import { default as create_45flashcards_45from_45pdf023fyXtDCLMeta } from "/workspace/pages/help/create-flashcards-from-pdf.vue?macro=true";
import { default as exporting_45flashcards_45to_45ankiHAZNcdIQKJMeta } from "/workspace/pages/help/exporting-flashcards-to-anki.vue?macro=true";
import { default as index50fsQJtH0RMeta } from "/workspace/pages/help/index.vue?macro=true";
import { default as share_45page1l9F32lGo5Meta } from "/workspace/pages/help/share-page.vue?macro=true";
import { default as homehESkjZ67H4Meta } from "/workspace/pages/home.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as knowledgeZLWBGSQfQtMeta } from "/workspace/pages/knowledge.vue?macro=true";
import { default as loginGeKhs9qf7uMeta } from "/workspace/pages/login.vue?macro=true";
import { default as logoutp3oIBCaMy6Meta } from "/workspace/pages/logout.vue?macro=true";
import { default as callbackHMdCLYvuAPMeta } from "/workspace/pages/oauth/google/callback.vue?macro=true";
import { default as onboardingl8gvvePqljMeta } from "/workspace/pages/onboarding.vue?macro=true";
import { default as _91flashcard_93MG3YWgjdDyMeta } from "/workspace/pages/page/[id]/flashcards/[flashcard].vue?macro=true";
import { default as indexgckfzUTfkkMeta } from "/workspace/pages/page/[id]/index.vue?macro=true";
import { default as _91study_93RQ3BE7mwgyMeta } from "/workspace/pages/page/[id]/study/[study].vue?macro=true";
import { default as _91pdf_9306vJNf5MzmMeta } from "/workspace/pages/pdf/[pdf].vue?macro=true";
import { default as pricingd8mZEa8d7UMeta } from "/workspace/pages/pricing.vue?macro=true";
import { default as registerHzIeMWcxyBMeta } from "/workspace/pages/register.vue?macro=true";
import { default as reset_password7OQEwiIv7wMeta } from "/workspace/pages/reset_password.vue?macro=true";
import { default as _91share_93JPEkSsvdUzMeta } from "/workspace/pages/s/[share].vue?macro=true";
import { default as image_45to_45flashcardsVwHCWvDNqQMeta } from "/workspace/pages/tools/image-to-flashcards.vue?macro=true";
import { default as pdf_45to_45flashcardssn6o1ghfxOMeta } from "/workspace/pages/tools/pdf-to-flashcards.vue?macro=true";
import { default as text_45to_45flashcardseCAVAaI9HZMeta } from "/workspace/pages/tools/text-to-flashcards.vue?macro=true";
import { default as _91blog_topic_9304O1gIeKliMeta } from "/workspace/pages/topic/[blog_topic].vue?macro=true";
export default [
  {
    name: "_dep_workspaces",
    path: "/_dep_workspaces",
    component: () => import("/workspace/pages/_dep_workspaces.vue")
  },
  {
    name: "admin-blog-create",
    path: "/admin/blog/create",
    component: () => import("/workspace/pages/admin/blog/create.vue")
  },
  {
    name: "admin-blog",
    path: "/admin/blog",
    component: () => import("/workspace/pages/admin/blog/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: index8x3i9klHioMeta || {},
    component: () => import("/workspace/pages/admin/index.vue")
  },
  {
    name: "admin-user",
    path: "/admin/user",
    meta: indexgWMKjCA6tUMeta || {},
    component: () => import("/workspace/pages/admin/user/index.vue")
  },
  {
    name: "assistant",
    path: "/assistant",
    meta: assistantNpE3wpoAOiMeta || {},
    component: () => import("/workspace/pages/assistant.vue")
  },
  {
    name: "blog-post",
    path: "/blog/:post()",
    meta: indexIU5K3dgkCOMeta || {},
    component: () => import("/workspace/pages/blog/[post]/index.vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexRgrKfU8NaHMeta || {},
    component: () => import("/workspace/pages/blog/index.vue")
  },
  {
    name: "canvas",
    path: "/canvas",
    component: () => import("/workspace/pages/canvas.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    meta: changelogInHvtgjw9VMeta || {},
    component: () => import("/workspace/pages/changelog.vue")
  },
  {
    name: "community-slug",
    path: "/community/:slug(.*)*",
    meta: _91_46_46_46slug_932Tk4dBzvWOMeta || {},
    component: () => import("/workspace/pages/community/[...slug].vue")
  },
  {
    name: "docs-privacy",
    path: "/docs/privacy",
    meta: privacylfhMLOhpAWMeta || {},
    component: () => import("/workspace/pages/docs/privacy.vue")
  },
  {
    name: "docs-tos",
    path: "/docs/tos",
    meta: tosKaBtqC7TH5Meta || {},
    component: () => import("/workspace/pages/docs/tos.vue")
  },
  {
    name: "duplicate-pageId",
    path: "/duplicate/:pageId()",
    meta: _91pageId_936WmMFF7uH6Meta || {},
    component: () => import("/workspace/pages/duplicate/[pageId].vue")
  },
  {
    name: "explore",
    path: "/explore",
    meta: exploreoxnV20dS4hMeta || {},
    component: () => import("/workspace/pages/explore.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqwxhi5zHHNsMeta || {},
    component: () => import("/workspace/pages/faq.vue")
  },
  {
    name: "features-ai",
    path: "/features/ai",
    meta: aiaH63aphHpSMeta || {},
    component: () => import("/workspace/pages/features/ai.vue")
  },
  {
    name: "features-flashcards",
    path: "/features/flashcards",
    meta: flashcardsEaFURn8qYlMeta || {},
    component: () => import("/workspace/pages/features/flashcards.vue")
  },
  {
    name: "features-image_to_pdf",
    path: "/features/image_to_pdf",
    meta: image_to_pdfSrpwo9L3lOMeta || {},
    component: () => import("/workspace/pages/features/image_to_pdf.vue")
  },
  {
    name: "features",
    path: "/features",
    meta: index11ooPhzO1bMeta || {},
    component: () => import("/workspace/pages/features/index.vue")
  },
  {
    name: "features-notes",
    path: "/features/notes",
    meta: notessOXHEHnmVAMeta || {},
    component: () => import("/workspace/pages/features/notes.vue")
  },
  {
    name: "forgot_password",
    path: "/forgot_password",
    meta: forgot_password1lkVCZfq00Meta || {},
    component: () => import("/workspace/pages/forgot_password.vue")
  },
  {
    name: "help-adding-flashcard-images",
    path: "/help/adding-flashcard-images",
    meta: adding_45flashcard_45images9qtfBA8au5Meta || {},
    component: () => import("/workspace/pages/help/adding-flashcard-images.vue")
  },
  {
    name: "help-ai-text-completion",
    path: "/help/ai-text-completion",
    meta: ai_45text_45completion6BtjXubXDnMeta || {},
    component: () => import("/workspace/pages/help/ai-text-completion.vue")
  },
  {
    name: "help-ai-text-edit",
    path: "/help/ai-text-edit",
    meta: ai_45text_45editBkiIpGVGS9Meta || {},
    component: () => import("/workspace/pages/help/ai-text-edit.vue")
  },
  {
    name: "help-create-flashcards-from-pdf",
    path: "/help/create-flashcards-from-pdf",
    meta: create_45flashcards_45from_45pdf023fyXtDCLMeta || {},
    component: () => import("/workspace/pages/help/create-flashcards-from-pdf.vue")
  },
  {
    name: "help-exporting-flashcards-to-anki",
    path: "/help/exporting-flashcards-to-anki",
    meta: exporting_45flashcards_45to_45ankiHAZNcdIQKJMeta || {},
    component: () => import("/workspace/pages/help/exporting-flashcards-to-anki.vue")
  },
  {
    name: "help",
    path: "/help",
    meta: index50fsQJtH0RMeta || {},
    component: () => import("/workspace/pages/help/index.vue")
  },
  {
    name: "help-share-page",
    path: "/help/share-page",
    meta: share_45page1l9F32lGo5Meta || {},
    component: () => import("/workspace/pages/help/share-page.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homehESkjZ67H4Meta || {},
    component: () => import("/workspace/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index9S03gOyIVcMeta || {},
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: "knowledge",
    path: "/knowledge",
    meta: knowledgeZLWBGSQfQtMeta || {},
    component: () => import("/workspace/pages/knowledge.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginGeKhs9qf7uMeta || {},
    component: () => import("/workspace/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutp3oIBCaMy6Meta || {},
    component: () => import("/workspace/pages/logout.vue")
  },
  {
    name: "oauth-google-callback",
    path: "/oauth/google/callback",
    meta: callbackHMdCLYvuAPMeta || {},
    component: () => import("/workspace/pages/oauth/google/callback.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingl8gvvePqljMeta || {},
    component: () => import("/workspace/pages/onboarding.vue")
  },
  {
    name: "page-id-flashcards-flashcard",
    path: "/page/:id()/flashcards/:flashcard()",
    component: () => import("/workspace/pages/page/[id]/flashcards/[flashcard].vue")
  },
  {
    name: "page-id",
    path: "/page/:id()",
    meta: indexgckfzUTfkkMeta || {},
    component: () => import("/workspace/pages/page/[id]/index.vue")
  },
  {
    name: "page-id-study-study",
    path: "/page/:id()/study/:study()",
    meta: _91study_93RQ3BE7mwgyMeta || {},
    component: () => import("/workspace/pages/page/[id]/study/[study].vue")
  },
  {
    name: "pdf-pdf",
    path: "/pdf/:pdf()",
    meta: _91pdf_9306vJNf5MzmMeta || {},
    component: () => import("/workspace/pages/pdf/[pdf].vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingd8mZEa8d7UMeta || {},
    component: () => import("/workspace/pages/pricing.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerHzIeMWcxyBMeta || {},
    component: () => import("/workspace/pages/register.vue")
  },
  {
    name: "reset_password",
    path: "/reset_password",
    meta: reset_password7OQEwiIv7wMeta || {},
    component: () => import("/workspace/pages/reset_password.vue")
  },
  {
    name: "s-share",
    path: "/s/:share()",
    meta: _91share_93JPEkSsvdUzMeta || {},
    component: () => import("/workspace/pages/s/[share].vue")
  },
  {
    name: "images_to_flashcards",
    path: "/tools/image-to-flashcards",
    meta: image_45to_45flashcardsVwHCWvDNqQMeta || {},
    component: () => import("/workspace/pages/tools/image-to-flashcards.vue")
  },
  {
    name: "pdf_to_flashcards",
    path: "/tools/pdf-to-flashcards",
    meta: pdf_45to_45flashcardssn6o1ghfxOMeta || {},
    component: () => import("/workspace/pages/tools/pdf-to-flashcards.vue")
  },
  {
    name: "text_to_flashcards",
    path: "/tools/text-to-flashcards",
    meta: text_45to_45flashcardseCAVAaI9HZMeta || {},
    component: () => import("/workspace/pages/tools/text-to-flashcards.vue")
  },
  {
    name: "topic-blog_topic",
    path: "/topic/:blog_topic()",
    meta: _91blog_topic_9304O1gIeKliMeta || {},
    component: () => import("/workspace/pages/topic/[blog_topic].vue")
  }
]